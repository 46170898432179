import requestAnimationFrame from 'raf';

import Diaporama from 'diaporama';

import diaporamaData from '../modules/landing/diaporama.json';

const diaporamaConfig = {
  loop: true,
  autoplay: true
};
const slideshow = Diaporama(
  document.getElementById('landing-diaporama'),
  diaporamaData,
  diaporamaConfig
);

const resizeDiaporama = () => {
  requestAnimationFrame(() => {
    const slideshowContainer = document.getElementById('landing-slideshow');
    const domRect = slideshowContainer.getBoundingClientRect();
    slideshow.width = domRect.width;
    slideshow.height = domRect.height;
  });
};

window.addEventListener('resize', resizeDiaporama);
resizeDiaporama();

const onLearnMoreClick = () => {
  document
    .getElementById('landing-about')
    .scrollIntoView({ behavior: 'smooth', block: 'start' });
};

document
  .getElementById('landing-learn-more-button')
  .addEventListener('click', onLearnMoreClick);
